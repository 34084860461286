<template>
  <div class="eb-search">
    <div class="row margin-0 justify-content-center">
      <div class="col-12 pad-0 search-content">
        <div class="row margin-0">
          <div class="col filter-temp hidden-xs" style="max-width:20%;">
            <div class="filter-base">
              <h5>Filter</h5>
            </div>
            <div class="filter-base">
              <h6 @click="actions.cat=!actions.cat">Category <span v-if="actions.cat" class="fa fa-chevron-up f-right"></span><span v-if="!actions.cat" class="fa fa-chevron-down f-right"></span></h6>
              <ul class="sub-cats" v-if="actions.cat">
                <li @click="updateFilter('category', null)" :class="{'active':searchFilter && searchFilter.category===null}"><span>All</span></li>
                <template v-for="(item, index) in cats">
                  <li :key="'cat_'+index" @click="updateFilter('category', item.id)" :class="{'active':searchFilter && searchFilter.category===item.id}"><span>{{item.title}}</span></li>
                </template>
              </ul>
            </div>
            <div class="filter-base" v-if="searchFilter.category && filter && filter.sub_category">
              <h6 @click="actions.sub_cat=!actions.sub_cat">{{catMappings[searchFilter.category]?catMappings[searchFilter.category]:searchFilter.category}} <span v-if="actions.sub_cat" class="fa fa-chevron-up f-right"></span><span v-if="!actions.sub_cat" class="fa fa-chevron-down f-right"></span></h6>
                <ul class="sub-cats" v-if="actions.sub_cat">
                  <li @click="updateFilter('sub_category', null)" :class="{'active':searchFilter && searchFilter.sub_category===null}"><span>All</span></li>
                  <template v-for="(item, index) in filter.sub_category">
                    <li :key="'subcat_'+index" @click="updateFilter('sub_category', index)" :class="{'active':searchFilter && searchFilter.sub_category===index}"><span>{{subCats[index]?subCats[index].title:index}} ({{item}})</span></li>
                  </template>
                </ul>
            </div>
          </div>
          <div class="col margin-bottom-30 sort-menu">
            <ad-list view="SEARCH" :filter="searchFilter" @reset="resetFilter" />
          </div>
        </div>
      </div>
    </div>
    <!-- mobile filter -->
    <div class="full-page-filter" v-if="mobile_filter">
      <div class="filter-head">
        Filter
        <ion-icon @click="mobile_filter=false" class="f-right" name="close-outline"></ion-icon>
      </div>
      <div class="filter-body">
        <div class="row margin-0">
          <div class="col-12 filter-group">
            <label for="">Category</label>
            <select name="" id="" v-model="twoFilter.category" class="form-control" placeholder="Select Category">
              <!-- <option v>All Categories</option> -->
              <template v-for="(item, key1) in cats">
                <option :key="key1" :value="key1">{{key1}}</option>
              </template>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AdList from '@/components/AdList.vue';
export default {
  props:['id'],
  components: {
    AdList
  },
  data () {
    return {
      searchFilter: {
        category: null,
        sub_category: null,
        search_text: null,
        city: null
      },
      list_type: 'grid',
      actions: {
        cat: true,
        sub_cat: true,
      },
      productsList: [],
      paramsList: {
        search: null,
        category: null,
        sub_category: null,
        state: null,
        city: null,
        area: null,
      },
      filter: null,
      loading_filter: true,
      mobile_filter: false,
      items: [],
      path: ['Home'],
      products: null,
      filter_by: '-created_on',
      search_label: null,
      pageSize: 100,
      params: null,
      cities: null,
      loading_list: true,
      oneFilter: {
        search: null,
        category: null,
        sub_category: null,
        state: null,
        city: null,
        area: null,
        sort: '-created_on',
        limit: 100,
      },
      twoFilter: {
        search: null,
        category: null,
        sub_category: null,
        state: null,
        city: null,
        area: null,
        sort: '-created_on',
        limit: 100,
      },
      selected_location: null
    }
  },
  computed: {
    ...mapState({
      location: (state) => state.location,
    })
  },
  created() {
    this.checkFilter();
    this.cities = this.$constants.cities;
    // this.planIt();
  },
  watch: {
    id: function() {
      this.checkFilter();
    }
  },
  methods: {
    getFilter() {
      this.loading_filter = true;
      this.$cs.search.create({
        data: this.searchFilter
      })
      .then(res => {
        this.filter = res.data;
        this.loading_filter = false;
      })
    },
    checkFilter() {
      console.log('checkFilter');
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      this.searchFilter = {
        category: null,
        sub_category: null,
        search_text: params.get("text") || null,
        city: null
      };
      if(this.id) {
        if(['Hyderabad', 'Chennai', 'Bengaluru', 'Mumbai', 'Delhi', 'Kolkata'].indexOf(this.id)!==-1) {
          this.searchFilter.city = this.id;
        } else if(this.$constants.search_cats.indexOf(this.id)!==-1) {
          this.searchFilter.category = this.id;
        } else {
          this.searchFilter.search_text = this.id;
        }
      }
      this.getFilter();
    },
    updateFilter(key, value) {
      switch(key) {
        case "category":
          this.searchFilter.category = value;
          this.searchFilter.sub_category = null;
          this.getFilter();
          break;
        case "sub_category":
          this.searchFilter.sub_category = value;
          break;
        case "city":
          this.searchFilter.city = value;
          this.getFilter();
          break;
      }
    },
    // old
    planIt() {
      console.log(this.catMappings, this.id);
      if(['Hyderabad', 'Chennai', 'Bengaluru', 'Mumbai', 'Delhi', 'Kolkata'].indexOf(this.id)!==-1) {
        this.oneFilter.city = this.id;
      } else {
        this.oneFilter.category = this.id;
      }
      if(this.location && this.location!==null) {
        this.oneFilter.city = this.location.city;
      }
      // this.getProducts();
      this.getFilter();
    },
    prepareParams() {
      console.log('fn:prepareParams');
      this.params = {};
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      this.paramsList.search = params.get("search") || null;
      if(['Hyderabad'].indexOf(this.id)!==-1) {
        this.paramsList.city = this.id;
      } else {
        this.paramsList.category = this.id;
      }
      for(var k in this.paramsList) if(this.paramsList[k]) this.params[k]=this.paramsList[k];
      this.getProducts();
      this.getFilter();
    },
    
    removeFilter(key) {
      this.oneFilter[key] = null;
      this.getProducts(true);
      switch(key) {
        case "category":
          this.getFilter();
          break;
        case "sub_category":
          break;
        case "city":
          this.getFilter();
          break;
      }
    },
    resetFilter() {
      this.searchFilter = {
        category: null,
        sub_category: null,
        search_text: null,
        city: null
      };
      this.getFilter();
      // switch(key) {
      //   case "cat":
      //     this.oneFilter.category = null;
      //     this.getProducts();
      //     this.getFilter();
      //     break;
      //   case "location":
      //     this.oneFilter.city = null;
      //     this.getProducts();
      //     this.getFilter();
      //     break;
      // }
    },
    openFilter() {
      this.twoFilter=Object.assign({}, this.oneFilter);
      this.mobile_filter=true;
    },
    applyFilter() {
      this.oneFilter=Object.assign({}, this.twoFilter);
    },
    getProducts(reset=false) {
      this.loading_list = true;
      const location = localStorage.getItem('location')?JSON.parse(localStorage.getItem('location')):null;
      this.$cs.product.create({
        data: {
          ...this.oneFilter,
          location
        },
        params: this.oneFilter,
        pageSize: this.pageSize
      })
      .then(res => {
        const ls = res.data;
        this.productsList = ls.results;
        this.products = res.data;
        this.loading_list = false;
      })
    },
    loadMore(page=null) {
      this.$cs.product.create({
        resource: page,
        data: this.oneFilter,
        params: this.oneFilter,
        pageSize: this.pageSize
      })
      .then(res => {
        const ls = res.data;
        this.productsList = [...this.productsList, ...ls.results];
        this.products = res.data;
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.search-content
  padding-top 3vh
.ads-list
  border-top 1px solid #ddd
  padding 5px 0px
.search-text
  padding 10px
.filter-base
  border-bottom 2px solid rgba(0,47,52,0.2)
  padding 4px 0px
  h5
    font-size 15px
    margin 0
  h6
    font-size 14px
    padding 8px 0px
    text-transform uppercase
    font-weight 700
    letter-spacing 0.3px
    margin 0
    .fa
      font-size 010px
  .sub-cats
    list-style none
    margin 0px
    padding 0px 0px 0px 0.8vw
    li
      font-size 0.9em
      color #777777
      font-weight 500
      padding 3px
      cursor pointer
      &.active
        color black
        font-weight 600
.list-type
  display flex
  justify-content flex-end
  padding 8px
  div
    padding-left 8px
    font-size 10px
    margin-left 10px
    &.b-left
      border-left 1px solid #ddd
    &.filter-list
      font-size 1em
      padding 4px
      span
        font-size 1em
        font-weight 500
        margin-right 5px
      select
        padding 2px 10px
  ion-icon
    height 35px
    color rgba(0,47,52,0.2)
    cursor pointer
    visibility: inherit
    &.active-type
      color #000
.eb-search
  min-height 100vh
  .sort-menu
    ion-icon
      font-size 1.7em
      margin-right 10px
  .filter-block
    position sticky
    top 18vh
    padding 10px
    background-color #fff
    margin-top 0.5vw
    border-bottom 1px solid #ddd
    // min-height 30vh
    h5
      font-size 0.9em
      text-transform uppercase
      letter-spacing 0.5px
    .sub-cats
      list-style none
      margin 0px
      padding 0px 0px 0px 1vw
      li
        font-size 0.9em
        color #777777
        font-weight 500
        padding 3px
        cursor pointer
        &.active
          color black
          font-weight 600
    .price-range
      display flex
      justify-content space-around
      input
        max-width 40%
        text-align center
        font-size 1em
      span
        padding 10px 0px
        font-weight bold
.foot-text
  letter-spacing 0.3px
  color #777
  font-size 0.75em
.full-page-filter
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  background-color #ffffff
  z-index 1110
  .filter-head
    padding 10px
    background-color #eee
    font-size 1em
    font-weight 600
    text-transform uppercase
    letter-spacing 0.4px
    ion-icon
      font-size 1.5em
      color red
  .filter-body
    .filter-group
      padding 10px
      label
        color #444
.filter-temp
  border-right 1px solid #ddd
  h4
    font-size 1em
    font-weight bold
    letter-spacing 0.5px
    padding 7px 3px
    border-bottom 2px solid #ddd
@media (max-width: 767px)
  .search-content
    padding-top 2vh
  .search-text
    padding 0px 10px
  .list-type
    padding 0px
    div
      padding-left 0px
      margin-left 0px
      ion-icon
        height 22px
      &.b-left
        border none
  .sort-menu
    padding 0px 0px
  .filter-block
    padding 0px
    background-color #fff
    margin-top 0.5vw
    border 1px solid #ddd
    min-height 30vh
  .ads-list
    list-style none
    margin 0px
    padding 0px
    width 100%
    li
      padding 10px 12px
      width 48%!important
      margin 1%!important
      img
        height 20vh
        max-width 90%
        display table
        margin 0 auto 10px
      .price
        display block
        font-weight bold
        font-size 1.3em
select.form-control
  -webkit-appearance: none
  border 2px solid #000000
  color #000000
  border-radius 0px
  &:not([multiple])
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    // padding: .5em;
    // padding-right: 1.5em;
    color #000000
.ads-list
    li:hover
      border-color #0594d9
.btn-filter
  letter-spacing 0.5px
  ion-icon
    font-size 0.8em!important
</style>
